import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
import * as gongju from '@/libs/gongju';
import Decimal from 'decimal.js';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import { ref, onMounted } from 'vue';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Popover } from 'vant';
import { Circle } from 'vant';
import { CountDown } from 'vant';
import { ActionSheet } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      search_value: '',
      banner: [],
      news: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      showPopover: false,
      all: [{
        top: -0.7,
        class: "1"
      }],
      dlang: {},
      count: 10,
      jizi: [],
      text: "7/10",
      chken: {},
      currentRate: 0,
      currentRate1: 0,
      ji: {
        ce1: 0,
        ce2: 100
      },
      chuan: [],
      time: 0,
      dsq: 0,
      app_root: api.base(),
      to: 0,
      dao2: [],
      app_root: api.base(),
      dao: {},
      jie: {},
      show: false,
      daoshow: 1,
      head_bg: ""
    };
  },
  setup() {
    // 使用ref引用DOM元素  
    const scrollContainer = ref(null);
    function scrollToCenter() {
      if (scrollContainer.value) {
        // 计算所有子元素的总宽度  
        console.log(scrollContainer.value.children[0].offsetWidth);
        let to = scrollContainer.value.children[0].offsetWidth / 10;
        let to1 = scrollContainer.value.children[0].offsetHeight / 10;
        // 设置滚动容器的scrollLeft属性  
        scrollContainer.value.scrollLeft = to * 3.3;
        scrollContainer.value.scrollTop = to1 * 3.4;
      }
    }

    // 在组件挂载后调用scrollToCenter方法  
    onMounted(() => {
      scrollToCenter();
    });
    return {
      scrollContainer
    };
  },
  created: function () {
    let _this = this;
    let init = 0;
    this.head_bg = local.readInfo('head_bg');
    //  console.log(this.all)
    _this.get_imdex_data();
  },
  methods: {
    onfinish() {
      let _this = this;
      _this.get_imdex_data();
    },
    qust() {
      showToast(this.lang.t6);
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    //切换语言
    select(e) {
      local.saveInfo('setting_lang', e);
      window.location.reload();
    },
    get_imdex_data: function () {
      api.all('/api/index/my_ship', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.user = data.data.user;
          this.chuan = data.data.chuan;
          this.dsq = data.data.dsq;
          this.time = data.data.time;
          this.dao2 = data.data.dao2;
        } else {
          console.log(data);
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
          //this.block = false
        }
      });
    },
    todao: function (id1) {
      api.all('/api/index/dao_detail', {
        id: id1
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.dao = data.data.dao;
          this.jie = data.data.jie == null ? {} : data.data.jie;
          this.dlang = data.data.lang;
          this.show = true;
          this.daoshow = 1;
        } else {
          console.log(data);
          //  if(data&&'msg' in data){
          showFailToast(data.msg);
          //  }
          //this.block = false
        }
      });
    },
    buydao: function (id1) {
      api.all('/api/index/unlock', {
        id: id1
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          setTimeout(() => {
            this.get_imdex_data();
          }, 1500);
          this.show = false;
          this.daoshow = 1;
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    },
    viewdao: function () {
      let dao = document.getElementsByClassName("van-action-sheet__content");
      console.log(dao);
      dao[0].scrollTop = 0;
      this.daoshow = 2;
    },
    goto: function (name) {
      let _this = this;
      _this.$router.push({
        name: name,
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    }
  }
};